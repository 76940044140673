<template>
  <div class="Atorvastatin-game">

    <div v-show="!result.win && !result.lose" class="game">

      <div class="question-text">Відгадайте призначення лікаря</div>

      <div class="list">

        <img class="bg" src="../assets/img/game-atorvastatin/stage6-icon.png" alt="">

        <div class="item">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="19" stroke="white" stroke-width="2"/>
            <path d="M19.2284 10C16.1048 16.1905 25.4767 19.2857 19.2284 28.5714" stroke="white" stroke-width="2"/>
            <path d="M13.5145 10C10.3909 16.1905 19.7628 19.2857 13.5145 28.5714" stroke="white" stroke-width="2"/>
            <path d="M24.9432 10C21.8196 16.1905 31.1915 19.2857 24.9432 28.5714" stroke="white" stroke-width="2"/>
          </svg>
          Тютюнопаління
        </div>
        <div class="item">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="19" stroke="white" stroke-width="2"/>
            <path
                d="M27.5716 23.8571C27.5716 28.3219 24.1363 31.8571 20.0001 31.8571C15.864 31.8571 12.4287 28.3219 12.4287 23.8571C12.4287 22.8401 12.885 21.3395 13.6811 19.5562C14.4614 17.8084 15.5101 15.9132 16.571 14.152C17.63 12.3941 18.6905 10.7872 19.4871 9.61876C19.6739 9.34469 19.8461 9.095 20.0001 8.8734C20.1542 9.095 20.3263 9.34469 20.5132 9.61876C21.3098 10.7872 22.3703 12.3941 23.4293 14.152C24.4902 15.9132 25.5389 17.8084 26.3191 19.5562C27.1153 21.3395 27.5716 22.8401 27.5716 23.8571Z"
                stroke="white" stroke-width="2"/>
          </svg>
          Підвищений рівень загального холестерину
        </div>
        <div class="item">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.6056 25.4161H18.4773L17.4345 5.71436H22.6483L21.6056 25.4161ZM17.1426 31.4208C17.1426 30.4004 17.4206 29.6809 17.9768 29.2622C18.5329 28.8436 19.2142 28.6343 20.0206 28.6343C20.7992 28.6343 21.4665 28.8436 22.0227 29.2622C22.5788 29.6809 22.8569 30.4004 22.8569 31.4208C22.8569 32.4412 22.5788 33.1738 22.0227 33.6186C21.4665 34.0634 20.7992 34.2858 20.0206 34.2858C19.2142 34.2858 18.5329 34.0634 17.9768 33.6186C17.4206 33.1738 17.1426 32.4412 17.1426 31.4208Z"
                fill="white"/>
            <circle cx="20" cy="20" r="19" stroke="white" stroke-width="2"/>
          </svg>
          Високий рівень ЛПНЩ
        </div>
        <div class="item">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.6056 25.4161H18.4773L17.4345 5.71436H22.6483L21.6056 25.4161ZM17.1426 31.4208C17.1426 30.4004 17.4206 29.6809 17.9768 29.2622C18.5329 28.8436 19.2142 28.6343 20.0206 28.6343C20.7992 28.6343 21.4665 28.8436 22.0227 29.2622C22.5788 29.6809 22.8569 30.4004 22.8569 31.4208C22.8569 32.4412 22.5788 33.1738 22.0227 33.6186C21.4665 34.0634 20.7992 34.2858 20.0206 34.2858C19.2142 34.2858 18.5329 34.0634 17.9768 33.6186C17.4206 33.1738 17.1426 32.4412 17.1426 31.4208Z"
                fill="white"/>
            <circle cx="20" cy="20" r="19" stroke="white" stroke-width="2"/>
          </svg>
          Низький рівень ЛПВЩ

        </div>
      </div>

      <div class="game__grey-text">На Вашу думку, це:</div>


      <div class="game__btn-next-stage hover_type6" @click="result.lose = true; toTop()">
        <p>
          Діуретик
        </p>
      </div>
      <div class="game__btn-next-stage hover_type6" @click="result.lose = true; toTop()">
        <p>
          Цукрознижувальний засіб
        </p>
      </div>
      <div class="game__btn-next-stage hover_type6" @click="result.win = true; toTop()">
        <p>
          Статин
        </p>
      </div>

    </div>

    <div class="win" v-show="result.win">

      <div class="win__green-block blue stage1">

        <p class="win__text desk blue">
          Аторвастатин, 10мг! Це те, що призначив Вам лікар
        </p>

        <div class="win__arrow-down blue"></div>

      </div>

      <img class="win__img-circle" src="../assets/img/game-atorvastatin/win.png" alt="">

      <div class="win__text-center">Все правильно! Лікар призначив пацієнту статин і Ви про це здогадались.
      </div>

      <div class="win__info-text-btn">
        <div class="win__info-text">
          <p><span>Аторвастатин-Тева </span>— для запобігання серцево-судинним захворюванням: зменшення ризику
            виникнення інфаркту міокарда, інсульту, необхідності у проведенні процедур реваскуляризації та
            стенокардії*<sup>1</sup>
          </p>
        </div>
        <div class="win__info-btn" @click="winPopup = !winPopup">
          <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="23" cy="23" r="23" fill="#35786E"/>
            <path
                d="M21 14.0109C21 12.6703 21.6667 12 23 12C24.3333 12 25 12.6703 25 14.0109C25 14.6497 24.8313 15.1491 24.494 15.509C24.1647 15.8599 23.6667 16.0353 23 16.0353C21.6667 16.0353 21 15.3605 21 14.0109ZM24.8313 33H21.1566V17.9113H24.8313V33Z"
                fill="white"/>
          </svg>

        </div>
      </div>

      <div class="win__popup" v-show="winPopup">
        <div class="win__popup--close-btn" @click="winPopup = !winPopup">
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="13" cy="13" r="12.5" stroke="#00A03B"/>
            <path d="M19.3643 6.63599L6.63634 19.3639" stroke="#00A03B"/>
            <path d="M19.3643 19.3639L6.63634 6.63597" stroke="#00A03B"/>
          </svg>
        </div>
        <div class="win__popup--text ">*З повним переліком показань детальніше можна ознайомитись в інструкції для
          медичного застосування лікарського засобу.
        </div>
        <div class="win__popup--text">1. Інструкція для медичного застосування лікарського засобу АТОРВАСТАТИН-ТЕВА,
          таблетки вкриті плівкою оболонкою по 10мг. РП №UA/16377/01/01. Наказ МОЗ України №485 від 16.03.2021.
        </div>
        <div class="win__popup--text">Інформація про лікарський засіб. РП №UA/16377/01/01, №UA/16377/01/02. Для
          професійної діяльності медичних та фармацевтичних працівників.
        </div>
        <div class="win__popup--text">Аторвастатин-Тева (1 таблетка, вкрита плівковою оболонкою, містить 10мг або 20мг
          аторвастатину (у вигляді аторвастатину кальцію). Характеристики: 10мг: білі або майже білі, еліптичні,
          двоопуклі, гладенькі таблетки, розміром приблизно 9,7мм × 5,2мм; 20мг: білі або майже білі, еліптичні,
          двоопуклі, гладенькі таблетки, розміром приблизно 12,5мм × 6,6мм. Лікувальні властивості: препарат, що
          знижує рівень холестерину і тригліцеридів у сироватці крові. Інгібітор ГМГ-КоА-редуктази. Найбільш частими
          побічними реакціями є: міалгія, діарея, нудота, підвищення рівня аланінамінотрансферази (АлАТ) та печінкових
          ферментів.
        </div>
        <div class="win__popup--text">Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9;
          0-800-502-284. CBG-UA-00824. Термін дії матеріалу – 23.08.2024.
        </div>

      </div>

    </div>

    <div class="lose" v-show="result.lose">
      <div class="lose__red-block stage1">

        <p class="lose__text desk white">
          <span>Спробуйте ще раз! Можливо лікар вже робив таке призначення. Проте це не те, за чим до Вас звернулись.</span><br>
        </p>

        <div class="lose__arrow-down"></div>

      </div>

      <img class="lose__img-circle" src="../assets/img/game-atorvastatin/lose.png" alt="">

      <div class="lose__repeat hover_type6" @click="reset()">
        <p>
          Ще раз!
        </p>
      </div>

    </div>

    <div class="notes-wrapper" v-show="result.lose">
      <span>Інформація про лікарський засіб. РП №UA/16377/01/01, №UA/16377/01/02. Для професійної діяльності медичних та
      фармацевтичних працівників.</span>


      <span>Аторвастатин-Тева (1 таблетка, вкрита плівковою оболонкою, містить 10мг або 20мг аторвастатину (у вигляді
      аторвастатину кальцію). Характеристики: 10мг: білі або майже білі, еліптичні, двоопуклі, гладенькі таблетки,
      розміром приблизно 9,7мм × 5,2мм; 20мг: білі або майже білі, еліптичні, двоопуклі, гладенькі таблетки, розміром
      приблизно 12,5мм × 6,6мм. Лікувальні властивості: препарат, що знижує рівень холестерину і тригліцеридів у
      сироватці крові. Інгібітор ГМГ-КоА-редуктази. Найбільш частими побічними реакціями є: міалгія, діарея, нудота,
      підвищення рівня аланінамінотрансферази (АлАТ) та печінкових ферментів.</span>


      <span>Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284. CBG-UA-00824.
      Термін дії матеріалу – 23.08.2024.</span>
    </div>

    <div class="notes-wrapper desk" v-show="!result.win && !result.lose">
      <span>Інформація про лікарський засіб. РП №UA/16377/01/01, №UA/16377/01/02. Для професійної діяльності медичних та
      фармацевтичних працівників.</span>


      <span>Аторвастатин-Тева (1 таблетка, вкрита плівковою оболонкою, містить 10мг або 20мг аторвастатину (у вигляді
      аторвастатину кальцію). Характеристики: 10мг: білі або майже білі, еліптичні, двоопуклі, гладенькі таблетки,
      розміром приблизно 9,7мм × 5,2мм; 20мг: білі або майже білі, еліптичні, двоопуклі, гладенькі таблетки, розміром
      приблизно 12,5мм × 6,6мм. Лікувальні властивості: препарат, що знижує рівень холестерину і тригліцеридів у
      сироватці крові. Інгібітор ГМГ-КоА-редуктази. Найбільш частими побічними реакціями є: міалгія, діарея, нудота,
      підвищення рівня аланінамінотрансферази (АлАТ) та печінкових ферментів.</span>


      <span>Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284. CBG-UA-00824.
      Термін дії матеріалу – 23.08.2024.</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Atorvastatin-game",
  data: () => ({
    winPopup: false,

    result: {
      win: false,
      lose: false
    }
  }),
  methods: {
    toTop() {
      window.scrollTo(0, 0)
    },
    reset() {
      this.result.win = false
      this.result.lose = false
      window.scrollTo(0, 0)

    }
  }
}
</script>

<style scoped lang="scss">

@import "../assets/style/fonts.css";
@import "../assets/style/vars";

.Atorvastatin-game {
  max-width: 1240px;
  width: 100%;

  display: flex;
  flex-direction: column;

  padding-top: 40px;

  min-height: calc((var(--vh, 1vh) * 100) - (var(--height_header)));
  height: 100%;
  margin: 0 auto;

  .game {
    max-width: 650px;
    padding: 0 20px;
    padding-bottom: 20px;

    margin: 0 auto;

    width: 100%;

    &__btn-next-stage {
      border: 2px solid $green;
      border-radius: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 47px;
      background: white;

      padding: 14px 15px;
      margin: 5px auto;

      max-width: 320px;
      width: 100%;

      cursor: pointer;

      p {
        color: $green;

        font-family: $EB;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
      }
    }

    .question-text {
      text-align: center;
      color: $green;
      font-family: $B;
      font-size: 18px;
      line-height: 120%;
    }

    .list {
      padding: 0 10px;
      position: relative;

      background: #35786E;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      border-radius: 20px;
      margin-top: 20px;

      .bg {
        position: absolute;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        max-width: 200px;
        width: 100%;
        height: auto;

        display: block;
      }

      .item {
        display: flex;
        align-items: center;
        height: 66px;
        padding: 2px 40px 2px 0;

        border-bottom: 1px solid rgba(255, 255, 255, 0.3);

        font-size: 24px;
        line-height: 120%;
        font-family: $M;
        color: white;

        &:last-child {
          margin-bottom: 0;
        }

        svg {
          max-width: 40px;
          width: 100%;
          height: auto;

          margin-right: 20px;
        }
      }
    }

    &__grey-text {
      text-align: center;
      color: $grey;
      font-family: $M;
      opacity: .4;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .lose {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 650px;
    width: 100%;

    padding: 0 20px 30px 20px;

    margin: 0 auto;

    &__red-block {
      background: #C40000;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      max-width: calc(100% - 40px);
      width: 100%;
      margin: 0 auto;
      padding: 25px 15px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      border-radius: 14px;

      span {
        font-family: $EB;
      }
    }

    &__text {
      font-size: 20px;
      line-height: 120%;
      font-family: $M;
      color: white;
      text-align: center;
    }

    &__arrow-down {
      position: absolute;
      bottom: -9px;
      width: 0;
      left: 0px;
      right: 0;
      margin: 0 auto;
      height: 0;
      border-left: 24px solid transparent;
      border-right: 24px solid transparent;

      border-top: 10px solid #C40000;
    }

    &__img-circle {
      max-width: 358px;
      width: 100%;
      height: auto;
      display: block;
      margin: 6% auto 0 auto;
      border-radius: 50%;
    }

    &__repeat {
      margin: -3% auto 0 auto;
      border: 2px solid $green;
      border-radius: 35px;
      padding: 14px 15px;
      background: white;

      max-width: 230px;
      width: 100%;

      cursor: pointer;


      p {
        color: $green;

        font-family: $EB;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
      }
    }
  }

  .win {

    min-height: calc((var(--vh, 1vh) * 100) - (var(--height_header)) - 40px);

    position: relative;
    padding: 0 20px 0 20px;

    max-width: 650px;
    height: 100%;
    width: 100%;
    margin: 0 auto 0 auto;

    display: flex;
    flex-direction: column;


    &__green-block {
      background: $green;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      max-width: calc(100% - 40px);
      width: 100%;
      margin: 0 auto;
      padding: 25px 15px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      border-radius: 14px;

      &.blue {
        background: $blue;
      }
    }

    &__text {
      font-size: 20px;
      line-height: 120%;
      font-family: $M;
      color: white;
      text-align: center;

      span {
        font-family: $EB;
      }
    }

    &__arrow-down {
      position: absolute;
      bottom: -9px;
      width: 0;
      left: 0px;
      right: 0;
      margin: 0 auto;
      height: 0;
      border-left: 24px solid transparent;
      border-right: 24px solid transparent;

      border-top: 10px solid $green;

      &.blue {
        border-top: 10px solid $blue;

      }
    }

    &__img-circle {
      max-width: 358px;
      width: 100%;
      height: auto;
      display: block;
      margin: 2% auto 0 auto;

    }

    &__text-center {
      text-align: center;
      color: $green;
      font-size: 20px;
      margin-bottom: 20px;
      line-height: 120%;
      font-family: $B;
      margin-top: -4%;
    }

    &__info-text-btn {
      margin: auto 0 40px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__info-text {

      max-width: 474px;
      width: 100%;
      margin-right: 15px;

      p {
        font-size: 16px;
        line-height: 120%;
        color: $grey;
        font-family: $M;
        vertical-align: center;
        position: relative;

        sup {
          font-size: 80%;
          line-height: 0;
          vertical-align: top;
        }
      }


      span {
        font-family: $EB;
      }
    }

    &__info-btn {
      margin-left: 15px;
      cursor: pointer;
    }

    &__popup {
      bottom: 40px;
      position: absolute;
      width: calc(100% - 20px);
      background: white;
      padding: 20px 20px 40px 20px;
      left: 50%;
      transform: translateX(-50%);

      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }

    &__popup--close-btn {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 24px;

      svg {
        display: block;
        cursor: pointer;

      }
    }

    &__popup--text {
      font-size: 10px;
      line-height: 120%;
      font-family: $M;
      color: $grey;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .notes-wrapper {
    max-width: 1280px;
    width: 100%;
    margin: auto auto 0 auto;

    padding: 16px 20px;

    font-size: 8px;
    line-height: 8px;
    font-family: $M;
    color: $grey;
    span {
      display: block;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.active {
      color: white;
    }
  }
}

@media screen and (max-width: 600px) {
  .Atorvastatin-game {
    padding-top: 20px;

    .game {
      padding-bottom: 20px;

      &__btn-next-stage {
        margin: 0 auto 20px auto;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .question-text {
      }

      .list {

        .bg {
        }

        .item {
          height: 55px;

          font-size: 18px;
          line-height: 120%;
          padding-right: 10px;

          svg {
            max-width: 28px;
            margin-right: 15px;
          }
        }
      }

      &__grey-text {
      }
    }

    .lose {

      &__red-block {
        font-size: 16px;
        line-height: 120%;
        padding: 15px;

        position: relative;
        top: 0;
      }

      &__text {
        font-size: 16px;
        line-height: 120%;
      }

      &__arrow-down {

      }

      &__img-circle {
        max-width: 100%;
        margin-top: -7%;
      }

      &__repeat {
        margin: -5% auto 0 auto;

        p {
        }
      }
    }

    .win {
      min-height: calc((var(--vh, 1vh) * 100) - (var(--height_header)) - 20px);

      &__green-block {
        font-size: 16px;
        line-height: 120%;
        padding: 15px;
        position: relative;
      }

      &__text {
        font-size: 16px;
        line-height: 120%;

        span {
        }
      }

      &__arrow-down {
      }

      &__img-circle {
        max-width: 100%;

        margin-top: -7%;

      }

      &__text-center {
        font-size: 16px;
        line-height: 120%;
        margin-top: -10%;
      }

      &__info-text-btn {
      }

      &__info-text {
        p {
          font-size: 12px;
          line-height: 120%;

          sup {

          }
        }

        span {
        }
      }

      &__info-btn {

      }

      &__popup {
        padding: 18px 20px 37px 20px;
        bottom: 10px;
      }

      &__popup--close-btn {


        svg {

        }
      }

      &__popup--text {
        font-size: 8px;
      }
    }

    .notes-wrapper {
      &.desk {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 434px) {
  .Atorvastatin-game {

    .lose {
      &__img-circle {
      }
    }
  }
}

</style>